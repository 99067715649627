import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { API_URL } from "../../auth/constants";
import { useAuth } from "../../auth/AuthProvider";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const year = new Date().getFullYear();

const FormUsuario = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const [initialValues, setInitialValues] = useState({
    idUser: "",
    contraseniaActual: "",
    contraseniaNueva: "",
  });

  const datosUsuario = async () => {
    try {
      const response = await axios.get(`${API_URL}/user/${auth.id}`);
      setUser(response.data.student);
      //console.log(user, "---", response.data.student.id_usuario);
      setInitialValues({
        idUser: response.data.student.id_usuario,
        contraseniaActual: "",
        contraseniaNueva: "",
      });
      //console.log(user, "---", response.data.student.id_usuario);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  useEffect(() => {
    datosUsuario();
  }, []);

  const handleFormSubmit = async (values) => {
    const valores = {
      idUser: initialValues.idUser,
      contraseniaActual: values.contraseniaActual,
      contraseniaNueva: values.contraseniaNueva
    }
    try {
      const response = await axios.put(`${API_URL}/user`, valores);
      //console.log("handleFormSubmit", response.data.student.status);
      //console.log("handleFormSubmit", response.data.student);

      if (response.data.student.status == 401) {
        //console.log("contraseña invalida");
        toast.error('Contraseña inválida', {
          icon: '⚠️',
          transition: Slide
        });
      }
      else if (response.data.student.status == 404) {
        //console.log("usuario no encontrado");
        toast.error('Usuario no encontrado', {
          icon: '⚠️',
          transition: Slide
        });
      }
      else {
        //console.log("registro echo");
        toast.success('Registro hecho', {
          icon: '✅',
          transition: Slide
        });
      }
    } catch (error) {
      console.error("error en el registro", error);
      toast.error('Error en el registro', {
        icon: '❌',
        transition: Slide
      });
    }
  };

  return (
    <Box m="20px">
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        transition={Slide}
        className="toast-container"
        toastClassName="dark-toast"
        style={{ top: "10%", transform: "translateY(-50%)" }} // Centra verticalmente
      />
      <Header title="Cambiar contraseña" subtitle="Ingrese contraseña actual y la nueva contraseña" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                maxWidth: 600,
                margin: "0 auto",
                marginBottom: "20px", // Espacio en la parte inferior
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {user ? (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ gridColumn: "span 4", display: "flex", alignItems: "center" }}
                >
                  Nombre: {user.nom_usu} {user.ape1_usu} {user.ape2_usu}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ gridColumn: "span 4", display: "flex", alignItems: "center" }}
                >
                  Cargando datos del usuario...
                </Typography>
              )}
              {user ? (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ gridColumn: "span 4", display: "flex", alignItems: "center" }}
                >
                  Usuario: {user.usuario}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ gridColumn: "span 4", display: "flex", alignItems: "center" }}
                >
                  Cargando datos del usuario...
                </Typography>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Contraseña actual"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contraseniaActual}
                name="contraseniaActual"
                error={!!touched.contraseniaActual && !!errors.contraseniaActual}
                helperText={touched.contraseniaActual && errors.contraseniaActual}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Nueva contraseña"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contraseniaNueva}
                name="contraseniaNueva"
                error={!!touched.contraseniaNueva && !!errors.contraseniaNueva}
                helperText={touched.contraseniaNueva && errors.contraseniaNueva}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>

            <Box gridColumn="span 4" display="flex" justifyContent="center">
              <Button type="submit" color="secondary" variant="contained">
                Registrar nueva contraseña
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  contraseniaActual: yup.string().required("La contraseña actual es requerida"),
  contraseniaNueva: yup.string().required("La nueva contraseña es requerida"),
  //idUser: yup.number().required("algo anda mal"),
});

export default FormUsuario;