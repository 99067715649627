import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Login from "./scenes/start/Login";
import Signup from "./scenes/start/Signup";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";
import FormUsuario from "./scenes/form/FormUsuario";
import TableUsuarios from "./scenes/contacts/TableUsuarios";
import ProtectedRoute from "./routes/publicRoutes/ProtectedRoute";
import AuthProvider, { useAuth } from "./auth/AuthProvider";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import axios from "axios";



function App() {


  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const auth = useAuth();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <div>
      <Routes>
        {auth.isAuthenticated ? (
          <Route
            path="/*"
            element={
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    <Sidebar isSidebar={isSidebar} />
                    <main className="content">
                      <Topbar setIsSidebar={setIsSidebar} />
                      <Routes>
                      <Route path="/" element={<Contacts />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/invoices" element={<Invoices />} />
                        <Route path="/form" element={<Form />} />
                        <Route path="/bar" element={<Bar />} />
                        <Route path="/pie" element={<Pie />} />
                        <Route path="/line" element={<Line />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/calendar" element={<Calendar />} />
                        <Route path="/geography" element={<Geography />} />
                        <Route path="/formuser" element={< FormUsuario />} />
                        <Route path="/users" element={<TableUsuarios/>} />
                        <Route path="/dashboard" element={<Dashboard/>} />
                        <Route path="/*" element={<Navigate to="/" />} />
                      </Routes>
                    </main>
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            }
          />
        ) : (
          <Route
            path="/*"
            element={
              <div className="app">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/*" element={<Navigate to="/login" />} />
                </Routes>
              </div>
            }
          />
        )}
      </Routes>
    </div>
  );
}

export default App;
