import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';
import { API_URL } from '../../auth/constants';
import { Button, TextField, Typography, Container, Box, CircularProgress, Grid } from '@mui/material';
import backgroundImage from '../../assets/fondo.jpg'; // Importa la imagen de fondo
import logoImage from '../../assets/logo.png'; // Importa la imagen del logo

const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorResponse, setErrorResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const goTo = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setIsLoading(true);
      const response = await fetch(`${API_URL}/login/docente`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName,
          password,
        }),
      });
  
      if (response.ok) {
        const json = await response.json();
        if (json.body.accessToken && json.body.refreshToken) {
          auth.saveUser(json);
          goTo('/contacts');
        }
      } else {
        // En caso de credenciales incorrectas, mostramos un mensaje al usuario
        const json = await response.json();

        setErrorResponse("Credenciales incorrectas. Por favor, inténtalo de nuevo.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  if (auth.isAuthenticated) {
    return <Navigate to="/contacts" />;
  }

  return (
    <Container
      maxWidth="lg"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        //filter: 'brightness(50%)', // Aplicando matizado solo al fondo
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* Contenido derecho con formulario de login */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'blue' }}>
            <Typography variant="h2" component="h1" sx={{ mb: 2 }}>
              LOGIN
            </Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userName"
                label="Username"
                name="userName"
                autoComplete="username"
                autoFocus
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!!errorResponse && (
                <Typography color="error" align="center" sx={{ mt: 1 }}>
                  {errorResponse}
                </Typography>
              )}
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                {isLoading ? <CircularProgress size={24} /> : 'Login'}
              </Button>
            </form>
            {/* <Link to="/signup">Don't have an account? Sign Up</Link> */}
          </Box>
        </Grid>
        <Grid item xs={6}>
          {/* Contenido izquierdo con la imagen */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logoImage} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;