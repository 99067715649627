import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { API_URL } from "../../auth/constants";
const year = new Date().getFullYear();
const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");


 //datos almacenados//

 const [nivel, setNivel] = useState([]);
 const [grado, setGrado] = useState([]);
 const [seccion, setSeccion] = useState([]);
 const [institute, setInstitute] = useState([]);
 const [zona, setZona] = useState([]);

 const getZona = async () => {
  try {
    const response = await axios.get(`${API_URL}/zona`);
    setZona(response.data.student);
    //console.log(zona,"---",response.data)
  } catch (error) {
    console.error("Error fetching estudiantes data:", error);
  }
  
};
const getInstitute = async () => {
  try {
    const response = await axios.get(`${API_URL}/institute`);
    setInstitute(response.data.student);
    //console.log(institute,"---",response.data)
  } catch (error) {
    console.error("Error fetching estudiantes data:", error);
  }
  
};
 
  const getNivel = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/nivel`);
      setNivel(response.data.student);
      //console.log(nivel,"---",response.data)
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
    
  };

  const getGrado = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/grado`);
      setGrado(response.data.student);
      //console.log(grado,"---",response.data)
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
    
  };
  const getSeccion = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/seccion`);
      setSeccion(response.data.student);
      //console.log("seccion:",seccion,"---",response.data)
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
    
  };

  const handleFormSubmit = (values) => {
    //console.log("Valores del formulario:", values);
    //console.log(`${API_URL}/estudiante`);
    axios
      .post(`${API_URL}/estudiante`, values)
      .then((response) => {
        //console.log("Respuesta del servidor:", response.data);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });
  };
  useEffect(() => {
    getNivel();
    getGrado();
    getSeccion();
    getZona();
    getInstitute();
  }, []);
  return (
    <Box m="20px">
      <Header title="Registrar estudiante" subtitle="Crear nuevo estudiante" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="DNI"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dni}
                name="dni"
                error={!!touched.dni && !!errors.dni}
                helperText={touched.dni && errors.dni}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nombre"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nombre}
                name="nombre"
                error={!!touched.nombre && !!errors.nombre}
                helperText={touched.nombre && errors.nombre}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Apellido Paterno"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apellidoPaterno}
                name="apellidoPaterno"
                error={!!touched.apellidoPaterno && !!errors.apellidoPaterno}
                helperText={touched.apellidoPaterno && errors.apellidoPaterno}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Apellido Materno"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apellidoMaterno}
                name="apellidoMaterno"
                error={!!touched.apellidoMaterno && !!errors.apellidoMaterno}
                helperText={touched.apellidoMaterno && errors.apellidoMaterno}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Edad"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.edad}
                name="edad"
                error={!!touched.edad && !!errors.edad}
                helperText={touched.edad && errors.edad}
                sx={{ gridColumn: "span 2" }}
              />
              <Select
                fullWidth
                variant="filled"
                label="Sexo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sexo}
                name="sexo"
                error={!!touched.sexo && !!errors.sexo}
                helperText={touched.sexo && errors.sexo}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="" disabled>
                  Sexo
                </MenuItem>
                <MenuItem value="MASCULINO">MASCULINO</MenuItem>
                <MenuItem value="FEMENINO">FEMENINO</MenuItem>
                <MenuItem value="Otro">Otro</MenuItem>
              </Select>
              <Select
                fullWidth
                variant="filled"
                type="text"
                label="Institución"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.institucion}
                name="institucion"
                error={!!touched.institucion && !!errors.institucion}
                helperText={touched.institucion && errors.institucion}
                sx={{ gridColumn: "span 4" }}
              >
                <MenuItem value="" disabled>
                  Institucion
                </MenuItem>
                {institute.map((item) => (
                                <MenuItem key={item.id_inst_est} value={item.id_inst_est}>
                                    {item.nom_inst_est}
                                </MenuItem>
                            ))}
              </Select>
              <Select
                fullWidth
                variant="filled"
                label="Nivel"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nivel}
                name="nivel"
                error={!!touched.nivel && !!errors.nivel}
                helperText={touched.nivel && errors.nivel}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="" disabled>
                  Nivel
                </MenuItem>
                {nivel.map((item) => (
                                <MenuItem key={item.id_nivel_est} value={item.id_nivel_est}>
                                    {item.nom_nivel_est}
                                </MenuItem>
                            ))}
              </Select>
              <Select
                fullWidth
                variant="filled"
                type="text"
                label="Grado"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.grado}
                name="grado"
                error={!!touched.grado && !!errors.grado}
                helperText={touched.grado && errors.grado}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="" disabled>
                  Grado
                </MenuItem>
                {grado.map((item) => (
                                <MenuItem key={item.id_grad_est} value={item.id_grad_est}>
                                    {item.nom_grad_est}
                                </MenuItem>
                            ))}
              </Select>
              <Select
                fullWidth
                variant="filled"
                type="text"
                label="Sección"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.seccion}
                name="seccion"
                error={!!touched.seccion && !!errors.seccion}
                helperText={touched.seccion && errors.seccion}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="" disabled>
                  Seccion
                </MenuItem>
                
                {seccion.map((item) => (
                                <MenuItem key={item.id_seccion_est} value={item.id_seccion_est}>
                                    {item.nom_seccion_est}
                                </MenuItem>
                            ))}

              </Select>
              <Select
                fullWidth
                variant="filled"
                type="text"
                label="Zona"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zona}
                name="zona"
                error={!!touched.zona && !!errors.zona}
                helperText={touched.zona && errors.zona}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="" disabled>
                  Zona
                </MenuItem>
                
                {zona.map((item) => (
                                <MenuItem key={item.id_zona_est} value={item.id_zona_est}>
                                    {item.nom_zona_est}
                                </MenuItem>
                            ))}

              </Select>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Talla"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.talla}
                name="talla"
                error={!!touched.talla && !!errors.talla}
                helperText={touched.talla && errors.talla}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Peso"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.peso}
                name="peso"
                error={!!touched.peso && !!errors.peso}
                helperText={touched.peso && errors.peso}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo Estudiante
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  dni: yup.string().required("El DNI es requerido"),
  nombre: yup.string().required("El nombre es requerido"),
  apellidoPaterno: yup.string().required("El apellido paterno es requerido"),
  apellidoMaterno: yup.string().required("El apellido materno es requerido"),
  edad: yup.number().required("La edad es requerida"),
  sexo: yup.string().required("El sexo es requerido"),
  institucion: yup.string().required("La institución es requerida"),
  nivel: yup.string().required("El nivel es requerido"),
  grado: yup.string().required("El grado es requerido"),
  seccion: yup.string().required("La sección es requerida"),
});

const token = localStorage.getItem("token");
const userId = token ? JSON.parse(token)?.user?.id : '';
const initialValues = {
  idUsu: userId,
  dni: "",
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  edad: "",
  sexo: "",
  institucion: "",
  nivel: "",
  grado: "",
  seccion: "",
  talla:"",
  peso:"",
  annio: year,
};

export default Form;
