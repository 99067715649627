export const tabla_h=
[
    {
      "EDAD": 5,
      "DE3_": 11.8,
      "DE2_": 12.7,
      "DE1_": 13.9,
      "Med": 15.2,
      "DE1": 16.9,
      "DE2": 18.9,
      "DE3": 21.3
    },
    {
      "EDAD": 6,
      "DE3_": 11.7,
      "DE2_": 12.7,
      "DE1_": 13.9,
      "Med": 15.3,
      "DE1": 17.0,
      "DE2": 19.2,
      "DE3": 22.1
    },
    {
      "EDAD": 7,
      "DE3_": 11.8,
      "DE2_": 12.7,
      "DE1_": 13.9,
      "Med": 15.4,
      "DE1": 17.3,
      "DE2": 19.8,
      "DE3": 23.3
    },
    {
      "EDAD": 8,
      "DE3_": 11.9,
      "DE2_": 12.9,
      "DE1_": 14.1,
      "Med": 15.7,
      "DE1": 17.7,
      "DE2": 20.6,
      "DE3": 24.8
    },
    {
      "EDAD": 9,
      "DE3_": 12.1,
      "DE2_": 13.1,
      "DE1_": 14.4,
      "Med": 16.1,
      "DE1": 18.3,
      "DE2": 21.5,
      "DE3": 26.5
    },
    {
      "EDAD": 10,
      "DE3_": 12.4,
      "DE2_": 13.5,
      "DE1_": 14.8,
      "Med": 16.6,
      "DE1": 19.0,
      "DE2": 22.6,
      "DE3": 28.4
    },
    {
      "EDAD": 11,
      "DE3_": 12.7,
      "DE2_": 13.9,
      "DE1_": 15.3,
      "Med": 17.2,
      "DE1": 19.9,
      "DE2": 23.7,
      "DE3": 30.2
    },
    {
      "EDAD": 12,
      "DE3_": 13.2,
      "DE2_": 14.4,
      "DE1_": 16.0,
      "Med": 18.0,
      "DE1": 20.8,
      "DE2": 25.0,
      "DE3": 31.9
    },
    {
      "EDAD": 13,
      "DE3_": 13.7,
      "DE2_": 15.1,
      "DE1_": 16.8,
      "Med": 19.0,
      "DE1": 22.0,
      "DE2": 26.5,
      "DE3": 33.8
    },
    {
      "EDAD": 14,
      "DE3_": 14.0,
      "DE2_": 15.4,
      "DE1_": 17.2,
      "Med": 19.6,
      "DE1": 22.7,
      "DE2": 27.3,
      "DE3": 34.7
    },
    {
      "EDAD": 15,
      "DE3_": 14.4,
      "DE2_": 15.9,
      "DE1_": 17.8,
      "Med": 20.2,
      "DE1": 23.5,
      "DE2": 28.2,
      "DE3": 35.5
    },
    {
      "EDAD": 16,
      "DE3_": 14.6,
      "DE2_": 16.2,
      "DE1_": 18.2,
      "Med": 20.7,
      "DE1": 24.1,
      "DE2": 28.9,
      "DE3": 36.1
    },
    {
      "EDAD": 17,
      "DE3_": 14.7,
      "DE2_": 16.4,
      "DE1_": 18.4,
      "Med": 21.0,
      "DE1": 24.5,
      "DE2": 29.3,
      "DE3": 36.3
    }
  ]
  export const tabla_m=
  [
    {
      "EDAD": 5,
      "DE3_": 12.1,
      "DE2_": 13.0,
      "DE1_": 14.1,
      "Med": 15.3,
      "DE1": 16.6,
      "DE2": 18.3,
      "DE3": 20.2
    },
    {
      "EDAD": 6,
      "DE3_": 12.1,
      "DE2_": 13.0,
      "DE1_": 14.1,
      "Med": 15.3,
      "DE1": 16.8,
      "DE2": 18.5,
      "DE3": 20.7
    },
    {
      "EDAD": 7,
      "DE3_": 12.3,
      "DE2_": 13.1,
      "DE1_": 14.2,
      "Med": 15.5,
      "DE1": 17.0,
      "DE2": 19.0,
      "DE3": 21.6
    },
    {
      "EDAD": 8,
      "DE3_": 12.4,
      "DE2_": 13.3,
      "DE1_": 14.4,
      "Med": 15.7,
      "DE1": 17.4,
      "DE2": 19.7,
      "DE3": 22.8
    },
    {
      "EDAD": 9,
      "DE3_": 12.6,
      "DE2_": 13.5,
      "DE1_": 14.6,
      "Med": 16.0,
      "DE1": 17.9,
      "DE2": 20.5,
      "DE3": 24.3
    },
    {
      "EDAD": 10,
      "DE3_": 12.8,
      "DE2_": 13.7,
      "DE1_": 14.9,
      "Med": 16.4,
      "DE1": 18.5,
      "DE2": 21.4,
      "DE3": 26.1
    },
    {
      "EDAD": 11,
      "DE3_": 13.1,
      "DE2_": 14.1,
      "DE1_": 15.3,
      "Med": 16.9,
      "DE1": 19.2,
      "DE2": 22.5,
      "DE3": 28.0
    },
    {
      "EDAD": 12,
      "DE3_": 13.4,
      "DE2_": 14.5,
      "DE1_": 15.8,
      "Med": 17.5,
      "DE1": 19.9,
      "DE2": 23.6,
      "DE3": 30.0
    },
    {
      "EDAD": 13,
      "DE3_": 13.8,
      "DE2_": 14.9,
      "DE1_": 16.4,
      "Med": 18.2,
      "DE1": 20.8,
      "DE2": 24.8,
      "DE3": 31.7
    },
    {
      "EDAD": 14,
      "DE3_": 14.3,
      "DE2_": 15.5,
      "DE1_": 17.0,
      "Med": 19.0,
      "DE1": 21.8,
      "DE2": 25.9,
      "DE3": 33.1
    },
    {
      "EDAD": 15,
      "DE3_": 14.7,
      "DE2_": 16.0,
      "DE1_": 17.6,
      "Med": 19.8,
      "DE1": 22.7,
      "DE2": 27.0,
      "DE3": 34.1
    },
    {
      "EDAD": 16,
      "DE3_": 15.1,
      "DE2_": 16.5,
      "DE1_": 18.2,
      "Med": 20.5,
      "DE1": 23.5,
      "DE2": 27.9,
      "DE3": 34.8
    },
    {
      "EDAD": 17,
      "DE3_": 15.4,
      "DE2_": 16.9,
      "DE1_": 18.8,
      "Med": 21.1,
      "DE1": 24.3,
      "DE2": 28.6,
      "DE3": 35.3
    }
  ]
  