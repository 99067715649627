import { Box, Hidden, InputLabel } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme, Button } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../auth/constants";
import React, { useEffect, useState } from "react";
import ModalDetStudent from "../modals/ModalDetStudent";

import {
  tablaimc_1,
  tablaimc_2,
  tablaimc_3,
  pesoimc_115_144,
  pesoimc_145_175,
  pesoimc_85_114,
  talla_m1,
  talla_m2,
  talla_m3,
  imcClasificacionGirl,
  imcClasificacionBoy,
} from "../../data/tablaimc";
import {
  TextField,
  MenuItem,
  Select,
  Grid,
  Typography,
  FormControl,
} from "@mui/material";
import { useAuth } from "../../auth/AuthProvider";
import { tabla_h, tabla_m } from "../../data/newtablaimc";

const Contacts = () => {
  const auth = useAuth();
  //
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token"); // Obtener el token del almacenamiento local
    if (token) {
      config.headers.Authorization = `${token}`; // Agregar el token al encabezado de autorización
    }
    return config;
  });
  //
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [estudiantes, setEstudiantes] = useState([]);
  const [pesotalla, setPesotalla] = useState({});
  const [editedTalla, setEditedTalla] = useState([]);
  const [editedPeso, setEditedPeso] = useState({});
  const [editedId, setEditedId] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const updateEditedTalla = (newEdit) => {
    setEditedTalla((prevEditedTalla) => {
      const existingIndex = prevEditedTalla.findIndex(
        (item) => item.id_est === newEdit.id_est
      );
      if (existingIndex !== -1) {
        // Actualiza el objeto existente
        const updatedTalla = [...prevEditedTalla];
        updatedTalla[existingIndex] = {
          ...updatedTalla[existingIndex],
          ...newEdit,
        };
        return updatedTalla;
      } else {
        // Añade un nuevo objeto
        return [...prevEditedTalla, newEdit];
      }
    });
  };
  //datos almacenados//

  const [nivel, setNivel] = useState([]);
  const [grado, setGrado] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [zona, setZona] = useState([]);

  const editTotTable = async (data) => {
    ////console.log("handleSave", data);
    try {
      const response = await axios.put(`${API_URL}/detStudent/tableest`, data);

      ////console.log("Estudiante editadopesotalla:", response);

      // Mostrar mensaje de éxito
      setSuccessMessage("¡Guardado exitosamente!");
      // Limpiar el mensaje después de 3 segundos
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error al eliminar estudiante:", error);
      // Mostrar mensaje de éxito
      setErrorMessage(
        "¡error posiblemente algun dato registrado no cumpla con los requerimiento!"
      );
      // Limpiar el mensaje después de 3 segundos
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };
  const getZona = async () => {
    try {
      const response = await axios.get(`${API_URL}/zona`);
      setZona(response.data.student);
      ////console.log(zona, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getInstitute = async () => {
    try {
      ////console.log("auth: ", auth.id);
      const response = await axios.get(`${API_URL}/institute/${auth.id}`);
      setInstitute(response.data.student);
      //console.log("faltaesto;", institute, "---", response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const getNivel = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/nivel`);
      setNivel(response.data.student);
      ////console.log(nivel, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const getGrado = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/grado`);
      setGrado(response.data.student);
      ////console.log(grado, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getSeccion = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/seccion`);
      setSeccion(response.data.student);
      ////console.log("seccion:", seccion, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const handleSave = async (data) => {
    ////console.log("handleSave", data);
    try {
      const response = await axios.put(`${API_URL}/estudiante`, data);

      // //console.log("Estudiante editadopesotalla:", response);

      // Mostrar mensaje de éxito
      setSuccessMessage("¡Guardado exitosamente!");

      // Limpiar el mensaje después de 3 segundos
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error al eliminar estudiante:", error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchEstudiantes = async (nivel1, grado1, seccion1, institucion1) => {
    try {
      if (!nivel1 || !grado1 || !seccion1 || !institucion1) {
        // Si algún campo está vacío, muestra un mensaje de error al usuario
        alert("Por favor complete todos los casilleros");
        return;
      }

      ////console.log("fetchEstudiantes", nivel1, grado1, seccion1, institucion1);
      const response = await axios.get(
        `${API_URL}/estudiante/${nivel1}/${grado1}/${seccion1}/${institucion1}/${auth.id}`
      );
      setEstudiantes(response.data.student);
      /* //console.log(
         "fetchEstudiantes",
         estudiantes,
         "---",
         response.data.student
       );*/
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const saveAll = async (item) => {
    ////console.log("saveAll:", editedTalla);
    if (apiRef.current && typeof apiRef.current.getAllRowIds === "function") {
      const allRowIds = apiRef.current.getAllRowIds();
      const allRows = allRowIds.map((id) => apiRef.current.getRow(id));
      ////console.log("Todas las filas:", allRows);
      editTotTable(allRows);
    } else {
      console.error(
        "getAllRowIds() no es una función válida en apiRef.current"
      );
    }
  };
  const apiRef = useGridApiRef();

  useEffect(() => {
    // Llamada a la API para obtener la data de estudiantes
    getZona();
    getNivel();
    getSeccion();
    getGrado();
    getInstitute();
  }, []);

  const columns = [
    { field: "nom_est", headerName: "Nombre", flex: 1 },
    { field: "ape1_est", headerName: "Apellido Paterno", flex: 1 },
    { field: "ape2_est", headerName: "Apellido Materno", flex: 1 },
    { field: "nom_seccion_est", headerName: "Seccion", flex: 1 },
    { field: "nom_grad_est", headerName: "Grado", flex: 1 },
    { field: "nom_nivel_est", headerName: "Nivel", flex: 1 },
    { field: "edad", headerName: "edad", flex: 1 },
    {
      field: "talla_det_est",
      headerName: "Talla (m)",
      flex: 1,
      editable: true,
    },
    {
      field: "peso_det_est",
      headerName: "Peso (kg)",
      flex: 1,
      editable: true,
    },
    {
      field: "IMC",
      headerName: "IMC",
      flex: 1,
      renderCell: (params) => {
        const talla = parseFloat(
          editedTalla[params.row.id_est] || params.row.talla_det_est
        );
        const peso = parseFloat(
          editedPeso[params.row.id_est] || params.row.peso_det_est
        );

        let imc;
        let possi, possj;
        imc = peso / (talla * talla);
        if (imc) {
          imc = imc.toFixed(3);
        } else {
          imc = "";
        }
        /*if (params.row.peso_det_est && params.row.talla_det_est) {
          if (params.row.edad <= 18) {
            if (
              Number(params.row.talla_det_est).toFixed(2) >= 0.85 &&
              Number(params.row.talla_det_est).toFixed(2) <= 1.14 &&
              params.row.peso_det_est >= 10 &&
              params.row.peso_det_est <= 37
            ) {
              possj = talla_m1.indexOf(
                parseFloat(Number(params.row.talla_det_est).toFixed(2))
              );
              possi = pesoimc_85_114.indexOf(parseInt(params.row.peso_det_est));

              imc = tablaimc_1[possj][possi];
            } else if (
              params.row.talla_det_est > 1.14 &&
              params.row.talla_det_est <= 1.44 &&
              params.row.peso_det_est >= 12 &&
              params.row.peso_det_est <= 44
            ) {
              possj = talla_m2.indexOf(
                parseFloat(Number(params.row.talla_det_est).toFixed(2))
              );
              possi = pesoimc_115_144.indexOf(
                parseInt(params.row.peso_det_est)
              );
              imc = tablaimc_2[possj][possi];
            } else if (
              params.row.talla_det_est > 1.44 &&
              params.row.talla_det_est <= 1.75 &&
              params.row.peso_det_est >= 19 &&
              params.row.peso_det_est <= 52
            ) {
              possj = talla_m3.indexOf(
                parseFloat(Number(params.row.talla_det_est).toFixed(2))
              );
              possi = pesoimc_145_175.indexOf(
                parseInt(params.row.peso_det_est)
              );
              imc = tablaimc_3[possj][possi];
            } else {
              imc = "fuera de rango";
            }
          } else {
            imc = talla && peso ? (peso / (talla * talla)).toFixed(2) : null;
          }
        } else {
        }
        */
        return <Box>{imc}</Box>;
      },
    },
    {
      field: "Clasificacion",
      headerName: "Clasificacion",
      flex: 1,
      renderCell: (params) => {
        const talla = parseFloat(
          editedTalla[params.row.id_est] || params.row.talla_det_est
        );
        const peso = parseFloat(
          editedPeso[params.row.id_est] || params.row.peso_det_est
        );

        let imc;
        //let possi, possj;
        let response;
        let classification = "";
        let backgroundColor = "white";
        let cen = 0;
        imc = peso / (talla * talla);
        if (imc) {
          if (imc != "Infinity") {
            if (params.row.sexo_est == "MASCULINO") {
              tabla_h.map((item) => {
                if (item.EDAD == params.row.edad) {
                  cen = 1;
                  if (imc < item.DE2_) {
                    classification = "Delgadez";
                    backgroundColor = "#ffde75";
                  } else if (imc >= item.DE2_ && imc < item.DE2) {
                    classification = "Normal";
                    backgroundColor = "#b7d8a0";
                  } else if (imc >= item.DE2 && imc < item.DE3) {
                    classification = "Sobrepeso";
                    backgroundColor = "#dbd3e5";
                  } else if (imc >= item.DE3) {
                    classification = "Obesidad";
                    backgroundColor = "#9179af";
                  }
                }
              });
            } else if (params.row.sexo_est == "FEMENINO") {
              tabla_m.map((item) => {
                if (item.EDAD == params.row.edad) {
                  cen = 1;
                  if (imc < item.DE2_) {
                    classification = "Delgadez";
                    backgroundColor = "#ffde75";
                  } else if (imc >= item.DE2_ && imc < item.DE2) {
                    classification = "Normal";
                    backgroundColor = "#b7d8a0";
                  } else if (imc >= item.DE2 && imc < item.DE3) {
                    classification = "Sobrepeso";
                    backgroundColor = "#dbd3e5";
                  } else if (imc >= item.DE3) {
                    classification = "Obesidad";
                    backgroundColor = "#9179af";
                  }
                }
              });
            }
            if (cen == 0) {
              if (params.row.sexo_est == "MASCULINO") {
                if (imc < tabla_h[12].DE2_) {
                  classification = "Delgadez";
                  backgroundColor = "#ffde75";
                } else if (imc >= tabla_h[12].DE2_ && imc < tabla_h[12].DE2) {
                  classification = "Normal";
                  backgroundColor = "#b7d8a0";
                } else if (imc >= tabla_h[12].DE2 && imc < tabla_h[12].DE3) {
                  classification = "Sobrepeso";
                  backgroundColor = "#dbd3e5";
                } else if (imc >= tabla_h[12].DE3) {
                  classification = "Obesidad";
                  backgroundColor = "#9179af";
                }
              }
              if (params.row.sexo_est == "FEMENINO") {
                if (imc < tabla_m[12].DE2_) {
                  classification = "Delgadez";
                  backgroundColor = "#ffde75";
                } else if (imc >= tabla_m[12].DE2_ && imc < tabla_m[12].DE2) {
                  classification = "Normal";
                  backgroundColor = "#b7d8a0";
                } else if (imc >= tabla_m[12].DE2 && imc < tabla_m[12].DE3) {
                  classification = "Sobrepeso";
                  backgroundColor = "#dbd3e5";
                } else if (imc >= tabla_m[12].DE3) {
                  classification = "Obesidad";
                  backgroundColor = "#9179af";
                }
              }
            }
          }
        }

        /*
        if (params.row.peso_det_est && params.row.talla_det_est) {
          if (params.row.edad <= 18) {
            if (
              Number(params.row.talla_det_est).toFixed(2) >= 0.85 &&
              Number(params.row.talla_det_est).toFixed(2) <= 1.14 &&
              params.row.peso_det_est >= 10 &&
              params.row.peso_det_est <= 37
            ) {
              possj = talla_m1.indexOf(
                parseFloat(Number(params.row.talla_det_est).toFixed(2))
              );
              possi = pesoimc_85_114.indexOf(parseInt(params.row.peso_det_est));
              console.log("posiciones:",possj,"--",possi,"")
              imc = tablaimc_1[possj][possi];
            } else if (
              params.row.talla_det_est > 1.14 &&
              params.row.talla_det_est <= 1.44 &&
              params.row.peso_det_est >= 12 &&
              params.row.peso_det_est <= 44
            ) {
              possj = talla_m2.indexOf(
                parseFloat(Number(params.row.talla_det_est).toFixed(2))
              );
              possi = pesoimc_115_144.indexOf(
                parseInt(params.row.peso_det_est)
              );
              imc = tablaimc_2[possj][possi];
            } else if (
              params.row.talla_det_est > 1.44 &&
              params.row.talla_det_est <= 1.75 &&
              params.row.peso_det_est >= 19 &&
              params.row.peso_det_est <= 52
            ) {
              possj = talla_m3.indexOf(
                parseFloat(Number(params.row.talla_det_est).toFixed(2))
              );
              possi = pesoimc_145_175.indexOf(
                parseInt(params.row.peso_det_est)
              );
              imc = tablaimc_3[possj][possi];
            } else {
              imc = "fuera de rango";
            }
          } else {
            imc = talla && peso ? (peso / (talla * talla)).toFixed(2) : null;
          }
        } else {
        }

        let classification = "N/A";
        let backgroundColor = "white";

        if (imc && params.row.edad && params.row.sexo_est) {
          if (params.row.sexo_est=="FEMENINO") {
            const edadABuscar = parseFloat(Number(params.row.edad).toFixed(1));
            possj = imcClasificacionGirl.findIndex(
              (obj) => obj.edad === edadABuscar
            );

            if(imc<imcClasificacionGirl[possj].desnutricionSevera){
              classification = "Desnutricion severa";
              backgroundColor = "#ff8b8b";
            }
            else if(imc>=imcClasificacionGirl[possj].desnutricionModerada1 && imc<=imcClasificacionGirl[possj].desnutricionModerada2 ){
              classification = "Desnutricion moderada";
              backgroundColor = "#ffde75";
            }
            else if(imc>=imcClasificacionGirl[possj].normal1 && imc<=imcClasificacionGirl[possj].normal2 ){
              classification = "Normal";
              backgroundColor = "#b7d8a0";
            }
            else if(imc>=imcClasificacionGirl[possj].sobrepeso1 && imc<=imcClasificacionGirl[possj].sobrepeso2 ){
              classification = "Sobrepeso";
              backgroundColor = "#dbd3e5";
            }
            else if(imc>=imcClasificacionGirl[possj].obesidad){
              classification = "Obesidad";
              backgroundColor = "#9179af";
            }

          } else if (params.row.sexo_est=="MASCULINO") {
            const edadABuscar = parseFloat(Number(params.row.edad).toFixed(1));
            possj = imcClasificacionBoy.findIndex(
              (obj) => obj.edad === edadABuscar
            );


            if(imc<imcClasificacionBoy[possj].desnutricionSevera){
              classification = "Desnutricion severa";
              backgroundColor = "#ff8b8b";
            }
            else if(imc>=imcClasificacionBoy[possj].desnutricionModerada1 && imc<=imcClasificacionBoy[possj].desnutricionModerada2 ){
              classification = "Desnutricion moderada";
              backgroundColor = "#ffde75";
            }
            else if(imc>=imcClasificacionBoy[possj].normal1 && imc<=imcClasificacionBoy[possj].normal2 ){
              classification = "Normal";
              backgroundColor = "#b7d8a0";
            }
            else if(imc>=imcClasificacionBoy[possj].sobrepeso1 && imc<=imcClasificacionBoy[possj].sobrepeso2 ){
              classification = "Sobrepeso";
              backgroundColor = "#dbd3e5";
            }
            else if(imc>=imcClasificacionBoy[possj].obesidad){
              classification = "Obesidad";
              backgroundColor = "#9179af";
            }
          }

          
        }
      */
        /*
        if (imc) {
          const imcValue = parseFloat(imc);
          if (imcValue < 18.5) {
            classification = "Bajo peso";
            backgroundColor = "#b8dcea";
          } else if (imcValue >= 18.5 && imcValue < 24.9) {
            classification = "Normal";
            backgroundColor = "#c3d79a";
          } else if (imcValue >= 25 && imcValue < 29.9) {
            classification = "Sobrepeso";
            backgroundColor = "#b1a1c8";
          } else if (imcValue >= 30 && imcValue < 34.9) {
            classification = "Obesidad tipo I";
            backgroundColor = "#e36c08";
          } else if (imcValue >= 35 && imcValue < 39.9) {
            classification = "Obesidad tipo II";
            backgroundColor = "#4e6328";
          } else if (imcValue >= 40) {
            classification = "Obesidad tipo III";
            backgroundColor = "#f50300";
          } else {
            classification = "";
            backgroundColor = "";
          }
        }
*/
        return (
          <Box
            style={{
              backgroundColor,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {classification}
          </Box>
        );
      },
    },
    {
      field: "opciones",
      headerName: "Opciones",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave(params.row);
              //openModal(params.row); // Pasar los datos del estudiante al modal
            }}
          >
            Guardar
          </Button>
        </Box>
      ),
    },
  ];

  const [nivel1, setNivel1] = useState("");
  const [grado1, setGrado1] = useState("");
  const [seccion1, setSeccion1] = useState("");
  const [institucion1, setInstitucion1] = useState("");

  const handleSave1 = () => {
    // Aquí puedes realizar la lógica para guardar los valores seleccionados
    ////console.log("Nivel:", nivel1);
    ////console.log("Grado:", grado1);
    ////console.log("Sección:", seccion1);
    ////console.log("Institucion", institucion1);
    fetchEstudiantes(nivel1, grado1, seccion1, institucion1);
  };
  return (
    <>
      <Box m="20px">
        <Header
          title="Informacion de Estudiantes"
          subtitle="Ingrese Grado Seccion y Nivel para visualizar a los estudiantes"
        />
        <Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: "center" }}
          >
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="nivel1">Nivel</InputLabel>
                <Select
                  labelId="nivel1"
                  label="Nivel"
                  value={nivel1}
                  onChange={(e) => setNivel1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    NIVEL
                  </MenuItem>
                  {nivel.map((item) => (
                    <MenuItem key={item.id_nivel_est} value={item.id_nivel_est}>
                      {item.nom_nivel_est}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="grado">Grado</InputLabel>
                <Select
                  labelId="grado"
                  label="Grado"
                  value={grado1}
                  onChange={(e) => setGrado1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    GRADO
                  </MenuItem>
                  {grado.map((item) => (
                    <MenuItem key={item.id_grad_est} value={item.id_grad_est}>
                      {item.nom_grad_est}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="seccion">Seccion</InputLabel>
                <Select
                  labelId="seccion"
                  label="Sección"
                  value={seccion1}
                  onChange={(e) => setSeccion1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    SECCION
                  </MenuItem>
                  {seccion.map((item) => (
                    <MenuItem
                      key={item.id_seccion_est}
                      value={item.id_seccion_est}
                    >
                      {item.nom_seccion_est}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="institucion">Institucion</InputLabel>
                <Select
                  labelId="institucion"
                  label="Institucion"
                  value={institucion1} // Asegúrate de que estás utilizando `institucion1` como valor seleccionado
                  onChange={(e) => setInstitucion1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    INSTITUCION {institute.id_inst_est}
                  </MenuItem>
                  {Array.isArray(institute) && institute.length > 0 ? (
                    institute.map((item) => (
                      <MenuItem
                        key={item.dataValues.id_inst_est}
                        value={item.dataValues.id_inst_est}
                      >
                        {item.dataValues.nom_inst_est}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No institutes found
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                onClick={handleSave1}
                variant="contained"
                color="secondary"
                style={{ height: "50px" }}
              >
                Seleccionar
              </Button>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                onClick={saveAll}
                variant="contained"
                color="secondary"
                style={{ height: "50px" }}
              >
                Guardar todo
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row.id_est}
            rows={estudiantes}
            columns={columns}
            pageSize={10}
            checkboxSelection
            apiRef={apiRef}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Box>
      </Box>
      {successMessage && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            padding: "10px",
            background: "#4caf50",
            color: "#fff",
            zIndex: 9999,
          }}
        >
          {successMessage}
        </Box>
      )}
      {errorMessage && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            padding: "10px",
            background: "#ff0000",
            color: "#fff",
            zIndex: 9999,
          }}
        >
          {errorMessage}
        </Box>
      )}
      <ModalDetStudent
        isOpen={isModalOpen}
        onClose={closeModal}
        modalData={modalData}
      >
        <button onClick={closeModal}>Close Modal</button>
      </ModalDetStudent>
    </>
  );
};

export default Contacts;
